// jQuery(document).ready(function () {
//   //initialiseLoadMore();
// });

export function initialiseLoadMore() {
  jQuery('.js-load-more-btn-child').click(function () {

    let button = jQuery(this),
      data = {
        'action': 'loadmore_child',
        'query': jQuery(this).attr('data-query'),
        'page': jQuery(this).attr('data-page'),
      };

    jQuery.ajax({
      url: load_more_params_child.ajax_url,
      data: data,
      type: 'POST',
      beforeSend: function () {
        button.find('.js-load-more-btn-icon').show();
      },
      success: function (data) {
        if (data) {
          button.find('.js-load-more-btn-icon').hide();
          jQuery('.js-load-more').append(data);
          let current_page = parseInt(button.attr('data-page')) +1;
          button.attr('data-page', current_page);

          if (current_page === parseInt(button.attr('data-page-max')))
            button.remove();
        } else {
          button.remove();
        }
      }
    });
  });
}
