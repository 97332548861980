jQuery(document).ready(function () {
    jQuery('.modal-window').appendTo('body');

    const body = document.body;
    const buttons = document.querySelectorAll('.trigger[data-modal-trigger]');

    for (let button of buttons) {
        modalEvent(button);
    }

    function modalEvent(button) {
        button.addEventListener('click', () => {
            const trigger = button.getAttribute('data-modal-trigger');
            const modal = document.querySelector(`[data-modal=${trigger}]`);
            const contentWrapper = modal.querySelector('.content-wrapper');
            const close = modal.querySelector('.close');

            close.addEventListener('click', () => modal.classList.remove('open'));
            close.addEventListener('click', () => body.classList.remove('modal-open'));
            modal.addEventListener('click', () => modal.classList.remove('open'));
            contentWrapper.addEventListener('click', (e) => e.stopPropagation());

            modal.classList.toggle('open');
            body.classList.toggle('modal-open');
        });
    }
});
