import "../sass/main.scss";

import "slick-carousel/slick/slick";

import "./script-modal";


import { initialiseLoadMore } from "./load-more";

jQuery(document).ajaxComplete(function () {
  initialiseMain();
});

let pattern_row = '<svg preserveAspectRatio="none" viewBox="0 0 1920 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1920 36.9517L1920 41.0005L-7.64348e-06 41.0002L-6.87231e-06 36.8636C253.87 13.9511 590.37 9.75301e-05 959.5 0.000158062C1329.12 -0.00344206 1666.01 13.9843 1920 36.9517Z" fill="#F2F2F2"/></svg>';
let pattern_row_neutral = '<svg preserveAspectRatio="none" viewBox="0 0 1920 41" fill="#1993A4" xmlns="http://www.w3.org/2000/svg"><path d="M1920 36.9517L1920 41.0005L-7.64348e-06 41.0002L-6.87231e-06 36.8636C253.87 13.9511 590.37 9.75301e-05 959.5 0.000158062C1329.12 -0.00344206 1666.01 13.9843 1920 36.9517Z" fill="#1993A4"/></svg>';

jQuery(window).resize(function () {
  stickToSides();
});

jQuery(window).scroll(function() {
    if (jQuery(this).scrollTop() > 50) {
        jQuery('body').addClass('scrolled');
    } else {
        jQuery('body').removeClass('scrolled');
    }
});

jQuery(document).ready(function () {
  initialiseMain();
  initialiseLoadMore();
  stickToSides();
  addTitleOnPageLoad();
  addPattern();

  jQuery('input[type=radio]').after('<span>');
  jQuery('input[type=checkbox]').after('<span>');

  jQuery('.frm_checkbox > span, .frm_radio > span').click(function () {
    jQuery(this).prev('input').click();
  });

  jQuery('.slick').slick();

  let container = jQuery(document).width();

  if (container >= 1280) {
    jQuery('.sc_slideshow_manual').each(function () {
      let content = jQuery(this).find('.sc_slideshow_manual__content');

      jQuery(this).find('.sc_slideshow_manual__element__main').css({'margin-top': (content.height() + 70) + 'px'});
      jQuery(this).find('.slick').css({'margin-top': -content.height() + 'px'});
    });
  }

  // Détection des colonnes avec image style 1 pour le style de la colonne
  let wpbColumns = document.querySelectorAll('.wpb_column');
  wpbColumns.forEach(function(column) {
    if (column.querySelector('.image-style-1')) {
      column.classList.add('column-image-style-1');
    }
  });

  // Ajout classe pour gérer la bannière texte au centre
  document.querySelectorAll('.banner-thumbnail--disabled .banner__content').forEach(function(el) {
    var h1Element = el.querySelector('.h1, h1');
    if (h1Element && !h1Element.nextElementSibling) {
      el.classList.add('banner-text-center');
      document.querySelectorAll('.banner-thumbnail--disabled .breadcrumb').forEach(function(el) {
        el.classList.add('breadcrumb-center');
      });
    }
  });


  // Fonction pour filtrer les références
  jQuery('.js-reference_terms_filter').click(function () {
    jQuery(this).addClass('loading');

    let selectedOptionValue = jQuery('.js-reference_terms:selected').val();

    jQuery('.js-reference_terms').removeClass('js-reference_terms--active');
    let term_name = jQuery('.js-reference_terms:selected').attr('data-name');

    let data = {
      'action': 'references_filters',
      'term_slug': selectedOptionValue,
    };

    jQuery.ajax({
      url: load_more_params_child.ajax_url,
      data: data,
      type: 'POST',
      success: function (data) {
        jQuery('.page-reference__terms-title').remove();
        if(term_name !== undefined) {
          jQuery('<div class="page-reference__terms-title"><span class="h1 color-secondary">' + term_name + '</span></div>').insertBefore('#js-references-grid');
        }
        jQuery('#js-references-grid').html(data);

        // Modifier les liens de pagination
        jQuery('.pagination a').each(function() {
          var currentHref = jQuery(this).attr('href');
          var domaine = window.location.origin;
          var newHref = currentHref.replace(domaine + '/wp-admin/admin-ajax.php', domaine + '/nos-realisations');


          // Ajoutez le terme du filtre actuel comme paramètre d'URL
          if (selectedOptionValue !== undefined) {
            newHref += '?filter=' + selectedOptionValue;
          }

          jQuery(this).attr('href', newHref);
        });

        initialiseLoadMore();
      },
      complete: function () {
        jQuery('.js-reference_terms_filter').removeClass('loading');
      }
    });
  });

  var urlParams = new URLSearchParams(window.location.search);
  var filter = urlParams.get('filter');

  // Si un filtre est défini : l'afficher dans le select
  if (filter) {
    jQuery('.js-reference_terms[value="' + filter + '"]').prop('selected', true);
  }


  //fonction pour changer l'url lors du changement de page quand on a un filtre
  var paginationLinks = document.querySelectorAll('.pagination .page-number');
  paginationLinks.forEach(function(link) {
    link.addEventListener('click', function(e) {
      e.preventDefault();

      var currentPage = window.location.href;
      var newPage = this.getAttribute('href');

      var newUrl = newPage.replace(window.location.origin + '/wp-admin/admin-ajax.php', currentPage);
      window.location.href = newUrl;
    });
  });

  // Ajoute l'ancre #cards pour la pagination sauf la page avec le filtre : cause fonctionnement URL pour pagination
  if (!document.body.classList.contains('page-template-page-references')) {
    var paginationLinks = document.querySelectorAll('.pagination .page-number');
    paginationLinks.forEach(function(link) {
      link.href += '#cards';
    });
  }

});

// Fonction pour ajouter le titre si changement de pagination
function addTitleOnPageLoad() {
  var urlParams = new URLSearchParams(window.location.search);
  var filter = urlParams.get('filter');

  // Si un filtre est défini : ajouter le titre
  if (filter) {
    var termName = jQuery('.js-reference_terms[value="' + filter + '"]').attr('data-name');
    if(termName !== undefined) {
      jQuery('<div class="page-reference__terms-title"><span class="h1 color-secondary">' + termName + '</span></div>').insertBefore('#js-references-grid');
    }
  }
}

function initialiseMain() {
  /*
   * Pouvoir cliquer sur l'ensemble d'une carte même avec la mollette de la souris
   */
  jQuery('.card').on('mousedown', function (event) {
    const link = jQuery(this).find('.js-button').attr('href');
    if (!link) return;

    if (event.button === 0) {
      window.location.href = link;
    } else if (event.button === 1) {
      event.preventDefault();
      window.open(link);
    }
  });
}

function stickToSides() {
  let maxWidth = 2300;
  let $window = jQuery(window);

  jQuery('.stickToLeft').each(function () {
    let offset = jQuery(this).offset().left;
    let width = jQuery(this).width();
    var left = offset;

    if ($window.width() < maxWidth)
      jQuery(this).css('margin-left', -offset);
    else
      jQuery(this).css('margin-left', '');

    jQuery(this).width(width + left);
  });

  jQuery('.stickToRight').each(function () {
    let offset = jQuery(this).offset().left;
    let width = jQuery(this).width();
    var right = $window.width() - (offset + width)

    if ($window.width() < maxWidth)
      jQuery(this).css('margin-right', -right);
    else
      jQuery(this).css('margin-right', '');

    jQuery(this).width(width + right);
  });
}

function addPattern() {
  jQuery('.pattern-before:not(.pattern-before-is-loaded)').each(function () {
    let pattern_use = pattern_row;

    let elt_class = jQuery(this).attr('class').split(' ');
    let pattern_color = 'color-background-white';
    let pattern_bg_color = '';
    for (let i = 0; i < elt_class.length; i++) {
      if (elt_class[i].slice(0, 20) === 'pattern-before-color') {
        pattern_color = elt_class[i].slice(15);
      }

      if (elt_class[i].slice(0, 23) === 'pattern-before-bg-color') {
        pattern_bg_color = elt_class[i].slice(15);
      }
    }

    let block = jQuery('<div class="block-pattern block-pattern-before ' + pattern_color + ' ' + pattern_bg_color + '">' + pattern_use + '</div>').prependTo(jQuery(this));
    let height = block.height();

    jQuery(this)
        .css('overflow', 'visible')
        .css('position', 'relative');

    if (!jQuery(this).hasClass('pattern-before-no-margin'))
      jQuery(this).css('margin-top', height);

    jQuery(this).addClass('pattern-before-is-loaded');

  });

  jQuery('.pattern-before-primary:not(.pattern-before-is-loaded)').each(function () {
    let pattern_use = pattern_row_neutral;

    let elt_class = jQuery(this).attr('class').split(' ');
    let pattern_color = 'color-background-primary';
    let pattern_bg_color = '';
    for (let i = 0; i < elt_class.length; i++) {
      if (elt_class[i].slice(0, 20) === 'pattern-before-color') {
        pattern_color = elt_class[i].slice(15);
      }

      if (elt_class[i].slice(0, 23) === 'pattern-before-bg-color') {
        pattern_bg_color = elt_class[i].slice(15);
      }
    }

    let block = jQuery('<div class="block-pattern block-pattern-before ' + pattern_color + ' ' + pattern_bg_color + '">' + pattern_use + '</div>').prependTo(jQuery(this));
    let height = block.height();

    jQuery(this)
        .css('overflow', 'visible')
        .css('position', 'relative');

    if (!jQuery(this).hasClass('pattern-before-no-margin'))
      jQuery(this).css('margin-top', height);

    jQuery(this).addClass('pattern-before-is-loaded');

  });
}